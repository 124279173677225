<template>
  <div>
    <b-card>
      <input
        v-model="date"
        type="date"
        @input="getReserves"
      >
    </b-card>
    <b-card
      v-for="reserve in reserves"
      class="d-flex align-items-center justify-content-beetween"
      role="button"
    >
      <router-link
        :to="{ name: 'Client-show', params: { id: reserve.user.id } }"
        class="text-dark"
      >
        Użytkownik: {{ reserve.user.name }}
        <br>
        Oczekiwana data: {{ reserve.date }}
        <br>
        Oczekiwana godzina: {{ reserve.start_time }}
      </router-link>
    </b-card>

    <b-card v-if="!reserves[0]">
      Brak wpisów do wyświetlenia
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      reserves: [],
      date: new Date().toISOString().split('T')[0],
    }
  },
  mounted() {
    document.title = 'Lista rezerwowa - Pickmode'

    this.getReserves()
  },
  methods: {
    getReserves() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Reserve-list/Business/${this.date}`, config)
        .then(response => {
          this.reserves = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
